var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("the-wrapper-dapp", {
    attrs: {
      "is-new-header": true,
      "dapp-img": _vm.headerImg,
      "banner-text": _vm.header,
      "tab-items": _vm.tabs,
      "active-tab": _vm.activeTab,
      "valid-networks": _vm.validNetworks,
      "top-strip": "",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }